import {BaseService} from './BaseService';
import axios from 'axios';
import apiDashboard from './ApiDashboard';
import moment from 'moment';

export class PersonalDataService extends BaseService {
    async fetch(data: any) {
        try {
            delete data.celular;
            data.nascimento = moment(data.birthday, 'DD/MM/YYYY').format('YYYY-MM-DD');
            data.expedicaoDocumento = moment(data.expedicaoDocumento, 'DD/MM/YYYY').format('YYYY-MM-DD');

            return this.handleResponse(await apiDashboard.put(`api/dinheirosim/user/updateData`, data));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async getCities(state: string) {
        try {
            return this.handleResponse(await axios.get(`https://brasilapi.com.br/api/ibge/municipios/v1/${state}?providers=gov,dados-abertos-br,wikipedia`));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}